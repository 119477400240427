

















































import HollowCard from '@/components/common/HollowCard.vue'
import PicklItem from '@/components/common/NonGeoPicklItem.vue'
import PicklDetails from '@/components/common/PicklDetails.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Component({
  components: {
    HollowCard,
    PicklItem,
    PicklDetails
  }
})
export default class PicklHistory extends Vue {
  public active = 0
  public activePickl = null
  public statuses:any[] = []
  public pickls:any = []
  public limit: number = 100
  public offset: number = 0
  public totalCount: number = 0
  public loadingPickls: boolean = true
  public brandId: number = 0
  public stores:any = []
  public storeLocations:any[] = []
  public state:string = ''
  public timer:number = 0
  public storeLimitPerPage:number = 1000
  public storeOffset:number = 0
  public storeLocationLimitPerPage:number = 1000
  public storeLocationOffset:number = 0
  public locationId = ''
  public storeLocationId:any = ''
  public rowsOffset: number = 0
  public storeName: string = ''
  public states:any = []
  public stateId: string =''
  public onBoarded: number = 0
  public countries:any[] =[]
  public countryId:any = ''

  get hasMorePickls (): boolean {
    return this.pickls.length < this.totalCount
  }

  get disableClick (): boolean {
    return this.statuses[this.active].status === 'REJECTED'
  }

  pushToPicklDetailsPage (picklId: any) {
    this.$router.push('/brand/non-geo-pickl/' + picklId)
  }

  getStatesByCountry () {
    this.updateList()
    if (this.countryId !== '') {
      this.$http.get('states?country_id=' + this.countryId).then((response: any) => {
        this.states = response.body.data
      })
    } else {
      this.states = []
    }
  }

  getCountries () {
    this.$http.get('countries?service_enabled=true').then((response:any) => {
      this.countries = response.body.data
    })
  }

  getPicklsCount (): void {
    this.loadingPickls = true
    this.$store.dispatch('getNonGeoPicklBrandStatusCount', this.brandId).then((response: any) => {
      this.statuses = response.body.data
      let status = this.$route.query['status']
      if (status) {
        for (let i = 0; i < this.statuses.length; ++i) {
          if (this.statuses[i].status === status) {
            this.active = i
            break
          }
        }
      }
      this.loadingPickls = true
      this.getPickls()
    }, response => {
      this.loadingPickls = false
    })
  }

  getStores (clear: boolean = true): void {
    this.$store.dispatch('getStores', { limit: this.storeLimitPerPage, offset: this.storeOffset, sort: 'name-ASC' }).then((response: any) => {
      this.stores.push(...response.response.body.data)
      this.storeOffset += this.storeLimitPerPage
      if (this.storeOffset < response.response.body.count) {
        this.getStores()
      }
    })
  }

  clearList () {
    this.pickls = []
    this.rowsOffset = 0
  }

  updateList () {
    this.clearList()
    this.getPickls()
  }
  getStoreLocations (storeId:any, reset:boolean = true) {
    this.locationId = ''
    this.storeLocationId = ''
    if (storeId === '') {
      this.updateList()
      this.storeLocations = []

      return false
    }
    if (reset) {
      this.storeLocations = []
      this.storeLocationLimitPerPage = 50
      this.storeLocationOffset = 0
    }
    let q = { sort: 'id-ASC', storeId: storeId, 'limit': this.storeLocationLimitPerPage, offset: this.storeLocationOffset }
    this.$http
      .get('store_locations', { params: q })
      .then((response: any) => {
        if (this.storeLocationLimitPerPage === q.limit && this.storeLocationOffset === q.offset) {
          this.storeLocationOffset += this.storeLocationLimitPerPage
          this.storeLocations.push(...response.body.data)
          if (this.storeLocationOffset < response.body.count) {
            this.getStoreLocations(storeId, false)
          } else {
            this.updateStoreLocationId(null)
          }
        }
      }
      )
  }
  updateStoreLocationId (storeLocationId: any) {
    if (this.locationId === '') {
      this.storeLocationId = this.storeLocations.map(location => location.id).join(',')
    } else {
      this.storeLocationId = this.locationId
    }
    if (this.storeLocations.length > 0) {
      this.updateList()
    } else {
      this.clearList()
    }
  }

  getPickls (clear: boolean = true): void {
    let sort = Vue.common.statusSortOrder[this.statuses[this.active].status]
    this.loadingPickls = true
    this.$store.dispatch('getBrandPickls', { id: this.brandId, status: this.statuses[this.active].status, storeName: '', limit: this.limit, offset: this.offset, sort: sort, 'storeLocationId': this.storeLocationId, 'stateId': this.stateId, countryId: this.countryId, 'type': 'NON_GEO' }).then((response: any) => {
      if (response.options.status === this.statuses[this.active].status && this.offset === response.options.offset) {
        if (clear) this.pickls = []
        this.pickls.push(...response.response.body.data)
        this.totalCount = response.response.body.count
        this.loadingPickls = false
      }
    }, response => {
      this.loadingPickls = false
    })
    this.$router.push({ query: { status: this.statuses[this.active].status } })
  }
  loadMore () {
    this.offset += this.limit
    this.getPickls(false)
  }

  switchCard (index: number) {
    if (this.active !== index) {
      this.offset = 0
      this.totalCount = 0
      this.pickls = []
      this.active = index
      this.getPickls()
    }
  }
  UpdatePicklList () {
    this.getPicklsCount()
  }
  resetListing () {
    this.stateId = ''
    this.locationId = ''
    this.storeLocationId = ''
    this.storeName = ''
    this.storeLocations = []
    this.getPickls()
  }
  created () {
    this.brandId = this.$store.state.userData.brandId
    this.onBoarded = this.$store.state.userData.onBoarded
  }
  mounted () {
    this.getPicklsCount()
    this.getStores()
    this.getCountries()
  }
  @Watch('stateId')
  onPropertyChanged (value: string, oldValue: string) {
    let context = this
    clearTimeout(this.timer)
    this.timer = setTimeout(function () {
      context.updateList()
    }, 500 || 0)
  }
}
